var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{'pa-0': _vm.$vuetify.breakpoint.smAndDown}},[_c('v-card',{staticClass:"msa-list",class:{
      'px-0': _vm.$vuetify.breakpoint.mdAndUp,
      'pb-2': _vm.$vuetify.breakpoint.mdAndUp
    },attrs:{"id":"settings--card"}},[(_vm.initialised)?_c('div',{staticClass:"px-0"},_vm._l((_vm.settings),function(s,n){return _c('div',{key:s.key},[_c('div',{staticClass:"d-flex px-12 pt-6 flex-wrap",class:{
            'align-center': _vm.$vuetify.breakpoint.mdAndUp,
            'pb-6': _vm.$vuetify.breakpoint.mdAndUp,
            'align-start': _vm.$vuetify.breakpoint.smAndDown
          }},[_c('div',[_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"body-1",attrs:{"id":("settings--label-" + (s.key))}},[_vm._v(_vm._s(s.name))])]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('span',{staticClass:"body-2",attrs:{"id":("settings--description-" + (s.key))}},[_vm._v(_vm._s(s.description))])]):_vm._e()]),_c('v-spacer'),(s.type === 'boolean')?_c('div',[_c('v-switch',{class:{'mt-0': _vm.$vuetify.breakpoint.smAndDown, 'pt-0': _vm.$vuetify.breakpoint.smAndDown},attrs:{"id":'settings--control-' + s.key,"aria-labelledby":("settings--label-" + (s.key)),"aria-describedby":("settings--description-" + (s.key)),"disabled":!_vm.fnActionByName(_vm.uIActions.UPDATE_PREFERENCES)},on:{"change":function($event){return _vm.setSetting(s.key, s.value)}},model:{value:(s.value),callback:function ($$v) {_vm.$set(s, "value", $$v)},expression:"s.value"}})],1):_vm._e(),(s.type === 'select')?_c('div',{staticClass:"my-1 settings--select-ie-fix"},[_c('v-select',{attrs:{"hide-details":"","id":'settings--control-' + s.key,"outlined":"","items":s.allowedValues,"item-text":"description","item-value":"value","aria-labelledby":("settings--label-" + (s.key)),"aria-describedby":("settings--description-" + (s.key)),"disabled":!_vm.fnActionByName(_vm.uIActions.UPDATE_PREFERENCES)},on:{"change":function($event){return _vm.setSetting(s.key, s.value)}},model:{value:(s.value),callback:function ($$v) {_vm.$set(s, "value", $$v)},expression:"s.value"}})],1):_vm._e()],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"px-12 mt-2 pb-6"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(s.description))])]):_vm._e(),(n < _vm.settings.length - 1)?_c('v-divider'):_vm._e()],1)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }