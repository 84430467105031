<template>
  <v-container
    :class="{'pa-0': $vuetify.breakpoint.smAndDown}"
  >
    <v-card
      id="settings--card"
      class="msa-list"
      :class="{
        'px-0': $vuetify.breakpoint.mdAndUp,
        'pb-2': $vuetify.breakpoint.mdAndUp
      }"
    >
      <div
        v-if="initialised"
        class="px-0"
      >
        <div
          v-for="(s, n) in settings"
          :key="s.key"
        >
          <div
            class="d-flex px-12 pt-6 flex-wrap"
            :class="{
              'align-center': $vuetify.breakpoint.mdAndUp,
              'pb-6': $vuetify.breakpoint.mdAndUp,
              'align-start': $vuetify.breakpoint.smAndDown
            }"
          >
            <div>
              <div class="mb-2">
                <label
                  class="body-1"
                  :id="`settings--label-${s.key}`"
                >{{ s.name }}</label>
              </div>
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <span
                  class="body-2"
                  :id="`settings--description-${s.key}`"
                >{{ s.description }}</span>
              </div>
            </div>

            <v-spacer />

            <div v-if="s.type === 'boolean'">
              <v-switch
                :class="{'mt-0': $vuetify.breakpoint.smAndDown, 'pt-0': $vuetify.breakpoint.smAndDown}"
                v-model="s.value"
                :id="'settings--control-' + s.key"
                @change="setSetting(s.key, s.value)"
                :aria-labelledby="`settings--label-${s.key}`"
                :aria-describedby="`settings--description-${s.key}`"
                :disabled="!fnActionByName(uIActions.UPDATE_PREFERENCES)"
              />
            </div>
            <div
              v-if="s.type === 'select'"
              class="my-1 settings--select-ie-fix"
            >
              <v-select
                hide-details
                :id="'settings--control-' + s.key"
                outlined
                v-model="s.value"
                :items="s.allowedValues"
                item-text="description"
                item-value="value"
                @change="setSetting(s.key, s.value)"
                :aria-labelledby="`settings--label-${s.key}`"
                :aria-describedby="`settings--description-${s.key}`"
                :disabled="!fnActionByName(uIActions.UPDATE_PREFERENCES)"
              />
            </div>
          </div>
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="px-12 mt-2 pb-6"
          >
            <span class="body-2">{{ s.description }}</span>
          </div>
          <v-divider v-if="n < settings.length - 1" />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {UI_ACTIONS} from "@/utils/constants";

export default {
  data() {
    return {
      initialised: false,
      uIActions: UI_ACTIONS
    }
  },
  computed: {
    ...mapGetters({
      settingsByLocation: 'settingsByLocation',
      locationId: 'selectedSchoolId',
      fnActionByName: 'getActionByName'
    }),
    settings() {
      return this.settingsByLocation(this.locationId);
    }
  },
  methods: {
    setSetting(key, value) {
      const data = {
        key: key,
        value: value,
        locationId: this.locationId
      }
      this.$store.dispatch('setSetting', data);
    }
  },
  watch: {
    locationId(newSchool, oldSchool) {
      if (newSchool != null && newSchool !== oldSchool) {
        this.$store.dispatch('fetchSettings', this.locationId);
      }
    }
  },
  created: async function() {
    if (!this.settings || !this.settings.length) {
      await this.$store.dispatch('fetchSettings', this.locationId);
    }

    this.initialised = true;
  }
}
</script>

<style lang="scss" scoped>
  #settings--card .v-card__text label {
    color: $ads-navy;
  }
  .settings--select-ie-fix {
    min-width: 200px;
  }
</style>
