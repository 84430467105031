import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=1a610e60&scoped=true&"
import script from "./Settings.vue?vue&type=script&lang=js&"
export * from "./Settings.vue?vue&type=script&lang=js&"
import style0 from "./Settings.vue?vue&type=style&index=0&id=1a610e60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a610e60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VContainer,VDivider,VSelect,VSpacer,VSwitch})
